import { App, computed, ref } from 'vue'

type JsonObject = { [key: string]: any }

type I18nOptions = {
  default: string
  messages: JsonObject
}

class I18n {
  private messages: JsonObject = {}
  public currentLocale = ref('')

  setCurrentLocale(locale: string): void {
    this.currentLocale.value = locale
  }

  setMessages(messages: JsonObject): void {
    this.messages = messages
  }

  getLocale(key: string): string {
    const keys = key.split('.')
    return keys.reduce(
      (obj, k) => (obj && obj[k] !== undefined ? obj[k] : key),
      this.messages[this.currentLocale.value],
    )
  }
}

const i18n = new I18n()

export const setupI18n = (options: I18nOptions) => {
  return {
    install(app: App): void {
      i18n.setCurrentLocale(options.default)
      i18n.setMessages(options.messages)

      // Require src/types/i18n.d.ts file
      app.config.globalProperties.$t = i18n.getLocale.bind(i18n)
    },
  }
}

export type LangKey = 'th' | 'en' | 'mm'

export const useI18n = () => {
  const locale = computed({
    get: () => i18n.currentLocale.value as LangKey,
    set: (newValue: string) => i18n.setCurrentLocale(newValue),
  })
  const t = (key: string) => {
    return i18n.getLocale(key)
  }

  return { locale, t }
}
