<template>
  <div ref="dropdownContainer">
    <div>
      <button
        class="inline-flex px-2 py-1 rounded-md border border-gray-100 shadow-sm bg-white hover:bg-gray-50 text-sm font-medium text-gray-700"
        @click="toggleDropdown"
      >
        <img
          class="w-5 h-5 md:w-6 md:h-6 mr-2"
          :src="flagIcons[state.selectedIcon]"
        />
        {{ state.selectedLanguage }}
        <svg
          class="h-5 w-5 ml-1 -mr-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
    <div
      class="origin-top-right absolute z-10 right-0 mt-1 w-52 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
      :class="{ hidden: !isDropdownOpen }"
    >
      <div class="py-1" role="none" v-for="(item, key) in localeDropdown">
        <a
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          tabindex="-1"
          @click.prevent="selectLanguage(key)"
        >
          <img
            class="inline w-5 h-5 md:w-6 md:h-6 mr-1"
            :src="flagIcons[item.icon as FlagIconName]"
          />
          {{ item.name }}
        </a>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { useRoute, useRouter } from 'vue-router'
import { useI18n, LangKey } from '@/plugins/i18n'
import { FlagIconName, flagIcons } from '@/utils/icon'

export type LangOption = {
  [key in LangKey]: {
    name: string
    icon: FlagIconName
  }
}

const localeList: LangOption = {
  th: {
    name: 'ไทย',
    icon: 'thFlag',
  },
  en: {
    name: 'English',
    icon: 'enFlag',
  },
  mm: {
    name: 'မြန်မာ',
    icon: 'mmFlag',
  },
}

const { locale } = useI18n()
const router = useRouter()
const route = useRoute()
const isDropdownOpen = ref(false)
const dropdownContainer = ref<HTMLDivElement | null>(null)

const state = reactive<{
  selectedLanguage: String
  selectedIcon: FlagIconName
}>({
  selectedLanguage: 'ไทย',
  selectedIcon: 'thFlag',
})

watch(locale, () => {
  selectLanguage(locale.value)
})

onMounted(() => {
  selectLanguage(locale.value)
})

const localeDropdown = computed(() => {
  return localeList
})

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value
}

const selectLanguage = (language: LangKey) => {
  const langOption = localeList[language]
  state.selectedLanguage = langOption.name
  state.selectedIcon = langOption.icon as FlagIconName
  isDropdownOpen.value = false
  toggleLocale(language)
}

const toggleLocale = (lang: LangKey): void => {
  locale.value = lang

  if (!router) return

  router.replace({ query: { ...route.query, lang } })
}

onClickOutside(dropdownContainer, () => {
  isDropdownOpen.value = false
})
</script>
