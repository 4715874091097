import verify from '@/assets/verify-icon.svg'
import email from '@/assets/email-icon.svg'
import gift from '@/assets/gift-icon.svg'
import warning from '@/assets/warning-circle-icon.svg'
import mobileOtp from '@/assets/mobile-otp-icon.svg'
import mobilePhone from '@/assets/mobile-phone-icon.svg'
import checkedOutline from '@/assets/checked-outline.svg'
import iconPhone from '@/assets/icon-phone.svg'
import iconDuplicatePassport from '@/assets/icon-duplicate-passport.svg'
import laserInfo from '@/assets/id-card-back-info.svg'
import birthdateTh from '@/assets/birthdate-th-icon.svg'
import birthdateNonthai from '@/assets/birthdate-non-th-icon.svg'
import address from '@/assets/address-icon.svg'
import thFlag from '@/assets/th-flag.svg'
import enFlag from '@/assets/en-flag.svg'
import mmFlag from '@/assets/mm-flag.svg'

export const icons = {
  verify,
  email,
  gift,
  warning,
  mobileOtp,
  mobilePhone,
  checkedOutline,
  iconPhone,
  iconDuplicatePassport,
  laserInfo,
  birthdateTh,
  birthdateNonthai,
  address,
}

export const flagIcons = {
  thFlag,
  enFlag,
  mmFlag,
}

export type IconName = keyof typeof icons
export type FlagIconName = keyof typeof flagIcons
