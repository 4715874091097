<template>
  <div
    v-if="showHeader"
    class="relative bg-primary h-20 md:h-[6.5rem] 2xl:h-32 text-white font-medium flex items-center cursor-default"
  >
    <ToggleLocale
      v-if="props.showToggleLocale"
      :forceLocale="props.forceLocale"
      class="absolute top-0 right-0 mx-2 my-2"
    />
    <div class="w-full max-w-[940px] m-auto">
      <h1
        class="w-full md:text-[1.75rem] 2xl:text-3xl md:leading-[42px]"
        :class="props.titleClass"
        v-html="props.title"
      ></h1>
    </div>
  </div>
  <slot class="overflow-y-auto" />
</template>
<script lang="ts" setup>
import ToggleLocale from '@/components/common/toggle-locale/ToggleLocale.vue'

const props = withDefaults(
  defineProps<{
    title?: string
    titleClass?: string
    showToggleLocale?: boolean
    showHeader?: boolean
    forceLocale?: 'thai' | 'nonthai'
  }>(),
  {
    showToggleLocale: true,
    showHeader: true,
  },
)
</script>
